import React from "react"
import SEO from "../components/seo"
import home from "../images/home.jpg"
import chambaStyles from "./chamba.module.scss"
import Card from "../components/card"
import { graphql, Link } from "gatsby"

export default function Rent({ data, location }) {
  const products = data.rentEdges.edges
  return (
    <div className={chambaStyles.window}>
      <SEO
        title="Renta de equipo de producción profesional"
        image="https://images.unsplash.com/photo-1518929301966-019439ed8149?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=968&q=80"
        description="Contamos con equipo de producción: óptica, cámaras, luces, anteras y todo para producciones profesionales."
        url="/rent"
      />
      <h2>Renta de equipo de producción profesional</h2>
      <p>
        Descubre nuestro catálogo de equipo profesional para llamados y
        producción de contenido audiovisual.
        <br />
        <br />
        ¡Renta por día, semana o mes!
      </p>
      <div className={chambaStyles.container}>
        {products.map(edge => {
          return (
            <div className={chambaStyles.gridItem}>
              <Link
                to={`/rent/${edge.node.data.productSlug}`}
                title={`Link a ${edge.node.data.title}`}
                className={chambaStyles.link}
              >
                <Card
                  img={edge.node.data.productMainImage[0].thumbnails.full.url}
                  title={edge.node.data.productTitle}
                  description={edge.node.data.productShortDescription}
                  slug={edge.node.data.productSlug}
                />
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const { allAirtable } = graphql`
  query rent {
    rentEdges: allAirtable(
      filter: {
        table: { eq: "ProductionItems" }
        data: { productIsActive: { eq: true } }
      }
      sort: { order: ASC, fields: data___priority }
    ) {
      edges {
        node {
          data {
            productTitle
            productDescription {
              childMarkdownRemark {
                html
              }
            }
            productShortDescription
            productMainImage {
              thumbnails {
                large {
                  url
                  width
                  height
                }
                full {
                  url
                  width
                  height
                }
              }
            }
            productRentalPrice
            productNeedsOperator
            typeOfOperator
            canProvideOperator
            productOperatorPrice
            productSlug
          }
        }
      }
    }
  }
`
